import { config } from "@packages/config";
import ky from "ky";
import type { FormEvent } from "react";
import useSWRMutation from "swr/mutation";
import { pushNotification } from "../store";

function sendMessage(url: string, { arg }: { arg: FormData }) {
  return ky.post(url, {
    body: arg,
  });
}

export default function ContactForm() {
  const { isMutating, trigger } = useSWRMutation(
    `${config.urls.services.middleware}/api/emails/contact`,
    sendMessage,
    {
      onError() {
        pushNotification({
          message:
            "Erreur lors de l'envoi. Merci de ré-essayer dans 10 minutes",
          type: "error",
        });
      },
      onSuccess() {
        pushNotification({
          message: "Votre email a été envoyé correctement",
          type: "success",
        });
      },
    },
  );

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!(event.target instanceof HTMLFormElement)) {
      return;
    }

    const formData = new FormData(event.target);

    try {
      trigger(formData);
    } catch {
      // FIXME: call sentry here
    }
  }

  return (
    <form
      className="flex flex-col max-w-screen-md mx-auto"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col md:flex-row md:gap-2">
        <input
          className="Input w-full"
          data-testid="ContactForm__Firstname"
          name="firstname"
          placeholder="Prénom"
          type="text"
        />

        <input
          className="Input mt-2 md:mt-0 w-full"
          data-testid="ContactForm__Lastname"
          name="lastname"
          placeholder="Nom"
          type="text"
        />
      </div>

      <input
        className="Input mt-2"
        data-testid="ContactForm__Email"
        name="email"
        placeholder="Email"
        type="email"
      />

      <input
        className="Input mt-2"
        data-testid="ContactForm__Subject"
        name="subject"
        placeholder="Sujet"
        type="text"
      />

      <textarea
        className="TextArea textarea-md mt-2"
        data-testid="ContactForm__Message"
        name="message"
        placeholder="Message"
        rows={6}
      />

      <button
        type="submit"
        className="btn btn-primary mt-2"
        data-testid="ContactForm__SubmitButton"
      >
        {isMutating && <span className="loading loading-spinner" />}
        Envoyer
      </button>
    </form>
  );
}
